<template>
  <div
    style="background-color: white; height: 100%"
  >
    <!-- MOBILE -->
    <v-container
      v-if="$vuetify.breakpoint.mobile"
      fluid
      fill-height
      class="pa-0"
      style="background-color: white;"
    >
      <v-row
        no-gutters
        style="height: 100%;"
      >
        <v-col
          cols="12"
          style="height: 100%"
        >
          <v-toolbar
            flat
            width="100%"
            class="toolbar"
          >
            <v-btn
              icon
              rounded
              @click.stop="$router.back()"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-toolbar-title class="font-weight-light ml-n2">
              {{ clientName }}
            </v-toolbar-title>
          </v-toolbar>
          <v-list
            expand
            class="white pb-16"
          >
            <v-list-group
              v-for="(item, i) in clientSectionsMobile"
              :key="i"
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    style="white-space: normal;"
                    v-text="item.title"
                  />
                </v-list-item-content>
              </template>
              <v-list-item
                class="px-0"
              >
                <!-- CLIENT DATA -->
                <v-card
                  v-if="item.isClientData"
                  width="100%"
                >
                  <v-card-text
                    class="d-flex mt-n4"
                    style="min-height: 20px"
                  >
                    <div v-if="client.clientType==='company'">
                      <div>
                        {{ $t('clients|company_type') }}: {{ client.clientData.company_type }}
                      </div>
                      <div>
                        {{ $t('clients|share_capital') }}: {{ client.clientData.selected_currency ? client.clientData.selected_currency.symbol : '' }} {{ client.clientData.shared_capital }}
                      </div>
                      <div>
                        {{ $t('clients|registration_number') }}: {{ client.clientData.rcs_number }}
                      </div>
                      <div>
                        {{ $t('clients|date_of_registration') }}: {{ client.clientData.date_of_registration }}
                      </div>
                      <div>
                        {{ $t('clients|company_registration_place') }}: {{ client.clientData.place_of_registration }}
                      </div>
                      <div>
                        {{ $t('clients|address') }}: {{ client.clientData.address }}
                      </div>
                      <div>
                        {{ $t('clients|city') }}: {{ client.clientData.city }}
                      </div>
                      <div>
                        {{ $t('clients|postal_code') }}: {{ client.clientData.postal_code }}
                      </div>
                      <div>
                        {{ $t('common|email') }}: {{ client.clientData.email }}
                      </div>
                      <div>
                        {{ $t('clients|company_representative') }}: {{ client.clientData.company_representative_name }}
                      </div>
                      <div>
                        {{ $t('clients|company_representative') }}  {{ $t('common|email') }}: {{ client.clientData.company_representative_email }}
                      </div>
                    </div>

                    <!-- Individual client type -->
                    <div v-else>
                      <div>
                        {{ $t('clients|date_of_birth') }}: {{ client.clientData.date_of_birth }}
                      </div>
                      <div>
                        {{ $t('clients|nationality') }}: {{ client.clientData.nationality }}
                      </div>
                      <div>
                        {{ $t('clients|place_of_birth') }}: {{ client.clientData.place_of_birth }}
                      </div>
                      <div>
                        {{ $t('clients|address') }}: {{ client.clientData.address }}
                      </div>
                      <div>
                        {{ $t('clients|city') }}: {{ client.clientData.city }}
                      </div>
                      <div>
                        {{ $t('clients|postal_code') }}: {{ client.clientData.postal_code }}
                      </div>
                      <div>
                        {{ $t('common|email') }}: {{ client.clientData.email }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- CLIENT CASES -->
                <v-card
                  v-if="item.isClientCases"
                  flat
                  color="lightBackground"
                  class="ma-0"
                  :width="$vuetify.breakpoint.width"
                >
                  <v-card-text
                    v-if="clientCases && clientCases.length"
                  >
                    <v-row class="">
                      <v-col
                        v-for="c in clientCases"
                        :key="c._id"
                        cols="12"
                      >
                        <CaseCardMini
                          :key="c._id"
                          :case-data="c"
                          :from-client-section="true"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text v-else>
                    {{ $t('cases|status_no_cases') }}
                  </v-card-text>
                </v-card>

                <!-- CLIENT DOCUMENTS -->
                <v-card
                  v-if="item.isClientDocuments"
                  width="100%"
                  flat
                  class="pb-10"
                >
                  <v-card-text class="mt-n6">
                    <div v-if="filteredDocumentsByClient && filteredDocumentsByClient.length">
                      <template
                        v-for="doc in filteredDocumentsByClient"
                      >
                        <ClientDocumentItem
                          :key="doc._id"
                          :document="doc"
                          class="my-4"
                          @open-doc="openDocument(doc)"
                          @deleteAction="unassignDocFromClient(doc)"
                        />
                      </template>
                    </div>
                    <div
                      v-else
                      class="text-center"
                    >
                      {{ $t('expressions|no_available_documents') }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-list-item>
            </v-list-group>
          </v-list>
          <FileView ref="fileView" />
        </v-col>
      </v-row>
      <v-toolbar
        flat
        color="primary"
        class="bottom-element-client"
      >
        <v-text-field
          v-model="searchDocument"
          flat
          prepend-inner-icon="mdi-magnify"
          :label="$t('actions|search_clients_documents')"
          hide-details
          dense
          dark
          class="my-2 mr-2"
        />

        <v-btn
          icon
          small
          dark
          class="mr-1"
          @click="goToClientProfile(client.clientType)"
        >
          <v-icon>
            {{ client.realClient ? 'mdi-eye' : 'mdi-pencil' }}
          </v-icon>
        </v-btn>

        <v-btn
          icon
          small
          dark
          class="mr-1"
          @click="openExportDocumentsMobile"
        >
          <v-icon>
            mdi-export
          </v-icon>
        </v-btn>

        <v-btn
          icon
          small
          dark
          @click="uploadDocumentsMobile()"
        >
          <v-icon>
            mdi-upload
          </v-icon>
        </v-btn>
      </v-toolbar>
      <FileView
        ref="fileView"
      />
    </v-container>

    <!-- DESKTOP -->
    <v-container
      v-else
      fluid
    >
      <v-row
        dense
        :style="`height:${($vuetify.breakpoint.height - 70).toString() + 'px'}`"
      >
        <v-col cols="9">
          <v-card
            :height="($vuetify.breakpoint.height - 70).toString()"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="8"
              >
                <v-card
                  flat
                >
                  <v-card-title class="d-flex align-center">
                    {{ clientName }}
                  </v-card-title>
                  <v-card-text
                    class="d-flex mt-n4"
                    style="min-height: 20px"
                  >
                    <v-col
                      cols="8"
                    >
                      <div v-if="client.clientType==='company'">
                        <div>
                          {{ $t('clients|company_type') }}: {{ client.clientData.company_type }}
                        </div>
                        <div>
                          {{ $t('clients|share_capital') }}: {{ client.clientData.selected_currency ? client.clientData.selected_currency.symbol : '' }} {{ client.clientData.shared_capital }}
                        </div>
                        <div>
                          {{ $t('clients|registration_number') }}: {{ client.clientData.rcs_number }}
                        </div>
                        <div>
                          {{ $t('clients|date_of_registration') }}: {{ client.clientData.date_of_registration }}
                        </div>
                        <div>
                          {{ $t('clients|company_registration_place') }}: {{ client.clientData.place_of_registration }}
                        </div>
                        <div>
                          {{ $t('clients|address') }}: {{ client.clientData.address }}
                        </div>
                        <div>
                          {{ $t('clients|city') }}: {{ client.clientData.city }}
                        </div>
                        <div>
                          {{ $t('clients|postal_code') }}: {{ client.clientData.postal_code }}
                        </div>
                        <div>
                          {{ $t('common|email') }}: {{ client.clientData.email }}
                        </div>
                        <div>
                          {{ $t('clients|company_representative') }}: {{ client.clientData.company_representative_name }}
                        </div>
                        <div>
                          {{ $t('clients|company_representative') }}  {{ $t('common|email') }}: {{ client.clientData.company_representative_email }}
                        </div>
                      </div>

                      <!-- Individual client type -->
                      <div v-else>
                        <div>
                          {{ $t('clients|date_of_birth') }}: {{ client.clientData.date_of_birth }}
                        </div>
                        <div>
                          {{ $t('clients|nationality') }}: {{ client.clientData.nationality }}
                        </div>
                        <div>
                          {{ $t('clients|place_of_birth') }}: {{ client.clientData.place_of_birth }}
                        </div>
                        <div>
                          {{ $t('clients|address') }}: {{ client.clientData.address }}
                        </div>
                        <div>
                          {{ $t('clients|city') }}: {{ client.clientData.city }}
                        </div>
                        <div>
                          {{ $t('clients|postal_code') }}: {{ client.clientData.postal_code }}
                        </div>
                        <div>
                          {{ $t('common|email') }}: {{ client.clientData.email }}
                        </div>
                      </div>
                    </v-col>
                  </v-card-text>
                </v-card>
                <v-dialog
                  v-model="companyDialog"
                  width="600"
                >
                  <CompanyClientForm
                    v-if="client && client.clientType === 'company'"
                    :client="client"
                    :has-back-button="false"
                    @submit="submit"
                    @close="companyDialog = false"
                  />
                </v-dialog>
                <v-dialog
                  v-model="individualDialog"
                  max-width="600"
                >
                  <v-container
                    style="background-color: white;"
                  >
                    <IndividualClientForm
                      v-if="client && client.clientType === 'individual'"
                      :client="client"
                      :has-back-button="false"
                      @submit="submit"
                      @close="individualDialog = false"
                    />
                  </v-container>
                </v-dialog>
                <FileView ref="fileView" />
              </v-col>
              <v-col
                cols="4"
              >
                <v-card flat>
                  <div class="d-flex flex-column mt-16 mx-2">
                    <v-btn
                      :fab="$vuetify.breakpoint.width < 1400"
                      color="primary"
                      rounded
                      small
                      @click="openEditDialog"
                    >
                      <v-icon
                        :left="$vuetify.breakpoint.width >= 1400"
                        :class="$vuetify.breakpoint.width >= 1400 ? 'icon-position' : ''"
                      >
                        {{ client.realClient ? 'mdi-eye' : 'mdi-pencil' }}
                      </v-icon>
                      <span v-if="$vuetify.breakpoint.width > 1400">
                        {{ client.realClient ? $t('profile|profile_overview') : $t('profile|edit_profile') }}
                      </span>
                    </v-btn>
                    <v-btn
                      :fab="$vuetify.breakpoint.width < 1400"
                      color="primary"
                      rounded
                      small
                      class="mt-3"
                      @click="openEditDialog"
                    >
                      <v-icon
                        :left="$vuetify.breakpoint.width >= 1400"
                        :class="$vuetify.breakpoint.width >= 1400 ? 'icon-position' : ''"
                      >
                        mdi-export
                      </v-icon>
                      <span v-if="$vuetify.breakpoint.width > 1400">
                        {{ $t('documents|export_documents') }}
                      </span>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-card
              flat
              class="ma-2"
              outlined
              :height="client.clientType === 'company' ? ($vuetify.breakpoint.height - 470).toString() : ($vuetify.breakpoint.height - 365).toString()"
              style="overflow-y: auto;"
            >
              <v-toolbar
                flat
                dense
                color="lightBackground"
                style="position: sticky; top: 0px; z-index: 1"
              >
                <v-card-title>
                  {{ $t('clients|client_cases') }}
                </v-card-title>
              </v-toolbar>
              <v-card-text
                v-if="clientCases && clientCases.length"
                style="overlow-y: auto;"
              >
                <v-row class="px-2 mt-2">
                  <div
                    v-for="c in clientCases"
                    :key="c._id"
                    class="mx-2"
                  >
                    <CaseCardMini
                      :key="c._id"
                      :case-data="c"
                      :from-client-section="true"
                    />
                  </div>
                </v-row>
              </v-card-text>
              <v-card-text v-else>
                {{ $t('cases|status_no_cases') }}
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            class="fill-height mr-1"
            :height="($vuetify.breakpoint.height - 71).toString()"
            style="overflow-y: auto;"
          >
            <v-toolbar
              flat
              dense
              color="lightBackground"
              style="position: sticky; top: 0px; z-index: 1"
            >
              <div
                class="justify-center"
              >
                {{ $t('clients|client_documents') }}
              </div>
            </v-toolbar>
            <div
              style="background-color: white; position: sticky; top: 48px; z-index: 1"
              class="pa-3"
            >
              <v-text-field
                v-model="searchDocument"
                flat
                prepend-inner-icon="mdi-magnify"
                :label="$t('actions|search_clients_documents')"
                rounded
                outlined
                hide-details
                dense
                class="shrink mx-3 mt-3"
              />
            </div>
            <v-card-text>
              <div v-if="filteredDocumentsByClient && filteredDocumentsByClient.length">
                <template
                  v-for="doc in filteredDocumentsByClient"
                >
                  <ClientDocumentItem
                    :key="doc._id"
                    :document="doc"
                    class="my-4"
                    @open-doc="openDocument(doc)"
                    @deleteAction="unassignDocFromClient(doc)"
                  />
                </template>
              </div>
              <div
                v-else
                class="center"
              >
                {{ $t('expressions|no_available_documents') }}
              </div>
            </v-card-text>
            <div class="text-center">
              <v-btn
                rounded
                small
                color="primary"
                @click="uploadDocument()"
              >
                <v-icon
                  small
                  left
                  class="icon-position"
                >
                  mdi-upload
                </v-icon>
                <span class="ml-5">{{ $t('actions|upload_documents') }}</span>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <FileView
        ref="fileView"
      />
    </v-container>
  </div>
</template>

<script>
import ClientActions from './ClientMixins/ClientActions'
import clientMixin from './ClientMixins/clientMixin'
import generalMixin from '@/utils/generalMixin.js'
import ClientDocumentItem from './ClientDocumentItem.vue'
import FileView from '@/components/dialogs/FileView.vue'

export default {
  components: {
    ClientDocumentItem,
    FileView
  },
  mixins: [generalMixin, ClientActions, clientMixin],
  computed: {
    clientSectionsMobile () {
      return [
        {
          action: 'mdi-account',
          title: this.$t('clients|client_data'),
          active: false,
          isClientData: true,
          isClientCases: false,
          isClientDocuments: false
        },
        {
          action: 'mdi-briefcase-edit',
          title: this.$t('clients|client_cases'),
          active: false,
          isClientData: false,
          isClientCases: true,
          isClientDocuments: false
        },
        {
          action: 'mdi-file',
          title: this.$t('clients|client_documents'),
          active: false,
          isClientData: false,
          isClientCases: false,
          isClientDocuments: true
        }
      ]
    }
  },
  methods: {
    goToClientProfile (input) {
      if (input && input === 'company') {
        this.$router.push({
          name: 'CompanyClientProfile',
          params: {
            client: this.client
          }
        })
      } else if (input && input === 'individual') {
        this.$router.push({
          name: 'IndividualClientProfile',
          params: {
            client: this.client
          }
        })
      }
    },
    openExportDocumentsMobile () {
      this.$router.push({
        name: 'ExportDocumentsMobile',
        params: {
          filteredDocumentsByClient: this.filteredDocumentsByClient,
          location: 'from-client-section'
        }
      })
    },
    uploadDocumentsMobile () {
      this.$router.push({
        name: 'AddDocumentsMobile',
        params: {
          client: this.client
        }
      })
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-position {
    position: absolute;
    left: 3px;
  }
  .toolbar {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
  .bottom-element-client {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
</style>
