import IndividualClientForm from '@/components/client/IndividualClientForm.vue'
import CompanyClientForm from '@/components/client/CompanyClientForm.vue'
import DocumentItem from '@/components/partials/DocumentItem.vue'
import CaseCardMini from '@/pages/Cases/CaseCardMini.vue'
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'

export default {
  components: {
    IndividualClientForm,
    CompanyClientForm,
    DocumentItem,
    CaseCardMini
  },
  data () {
    return {
      actionType: 'update',
      companyDialog: false,
      individualDialog: false,
      searchDocument: '',
      componentKey: 1
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments
    }),
    filteredDocuments () {
      let allDocuments = []
      let docs = []
      let comapnyDocs = []
      let groupDocs = []
      docs = this.documents.map((doc) => {
        const client = doc.clients[0]
        let clientName = ''
        let clientId = ''
        if (client) {
          clientName = (client && client.clientType === 'individual') ? (client.clientData.given_names + ' ' + client.clientData.surname) : client.clientData.company_name
          clientId = client._id
        }
        return {
          ...doc,
          fileType: doc.raw.mimetype,
          documentType: doc.info.document_type,
          clientName,
          clientId
        }
      })
      if (this.companyDocuments.length) {
        comapnyDocs = this.companyDocuments.map((doc) => {
          const client = doc.clients[0]
          let clientName = ''
          let clientId = ''
          if (client) {
            clientName = (client && client.clientType === 'individual') ? (client.clientData.given_names + ' ' + client.clientData.surname) : client.clientData.company_name
            clientId = client._id
          }
          return {
            ...doc,
            fileType: doc.raw.mimetype,
            documentType: doc.info.document_type,
            clientName,
            clientId
          }
        })
      }
      if (this.groupDocuments.length) {
        groupDocs = this.groupDocuments.map((doc) => {
          const client = doc.clients[0]
          let clientName = ''
          let clientId = ''
          if (client) {
            clientName = (client && client.clientType === 'individual') ? (client.clientData.given_names + ' ' + client.clientData.surname) : client.clientData.company_name
            clientId = client._id
          }
          return {
            ...doc,
            fileType: doc.raw.mimetype,
            documentType: doc.info.document_type,
            clientName,
            clientId
          }
        })
      }
      allDocuments.push(...docs, ...comapnyDocs, ...groupDocs)
      return allDocuments
    },
    filteredCompanyDocuments () {
      return this.companyDocuments.map((doc) => {
        const client = doc.clients[0]
        let clientName = ''
        let clientId = ''
        if (client) {
          clientName = (client && client.clientType === 'individual') ? (client.clientData.given_names + ' ' + client.clientData.surname) : client.clientData.company_name
          clientId = client._id
        }
        return {
          ...doc,
          fileType: doc.raw.mimetype,
          documentType: doc.info.document_type,
          clientName,
          clientId
        }
      })
    },
    filteredDocumentsByClient () {
      let matchDocArr = []
      for (let i = 0; i < this.filteredDocuments.length; i++) {
        matchDocArr = this.filteredDocuments.filter(d => d.clientId.includes(this.client._id))
        if (matchDocArr && this.searchDocument) {
          return matchDocArr.filter((d) => {
            return d.raw.originalname.toLowerCase().includes(this.searchDocument.toLowerCase())
          })
        }
      }
      return matchDocArr
    },
    client () {
      if (this.actualClient) return this.clients.find(c => c._id === this.actualClient._id) || this.companyClients.find(c => c._id === this.actualClient._id) || this.groupClients.find(c => c._id === this.actualClient._id)
      else return this.clients.find(c => c._id === this.$route.params._id) || this.companyClients.find(c => c._id === this.$route.params._id) || this.groupClients.find(c => c._id === this.$route.params._id)
    },
    clientName () {
      if (this.client.clientType === 'individual' && this.client.clientData.given_names && this.client.clientData.surname) {
        return (this.client.clientData.given_names.concat(' ' + this.client.clientData.surname)) || 'Not Set'
      } else if (this.client.clientType === 'individual' && this.client.clientData.given_names) {
        return this.client.clientData.given_names || 'Not Set'
      } else if (this.client.clientType === 'individual' && this.client.clientData.surname) {
        return this.client.clientData.surname || 'Not Set'
      }
      return this.client.clientData.company_name || 'Not Set'
    },
    clientCases () {
      let _cases = []
      this.cases.forEach(c => {
        if (c.client && c.client._id === this.client._id) {
          _cases.push(c)
        }
      })
      this.companyCases.forEach(c => {
        if (c.client && c.client._id === this.client._id) {
          _cases.push(c)
        }
      })
      this.groupCases.forEach(c => {
        if (c.client && c.client._id === this.client._id) {
          _cases.push(c)
        }
      })
      const returnCases = uniqBy(_cases, '_id')
      return returnCases
    }
  },
  watch: {
    filteredDocuments (val) {
      if (val) this.componentKey++
    }
  },
  methods: {
    ...mapActions({
      fetchClient: 'clients/fetchClient',
      updateDocument: 'documents/updateDocument'
    }),
    openEditDialog () {
      if (this.client.clientType === 'company') {
        this.companyDialog = true
      }
      if (this.client.clientType === 'individual') {
        this.individualDialog = true
      }
    },
    uploadDocument () {
      EventBus.$emit('dialog-add-document', this.client)
    },
    openExportDialog () {
      EventBus.$emit('dialog-export-document', this.filteredDocumentsByClient, 'from-client-section')
    },
    async unassignDocFromClient (doc) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      const clientIdIndex = doc.clients.findIndex(c => c._id === this.client._id)
      doc.clients.splice(clientIdIndex, 1)
      await this.updateDocument({
        _id: doc._id,
        payload: {
          clients: doc.clients
        }
      })
    },
    async deleteClient (client) {
      const res = await this.$dialog.confirm({
        text: this.$t('clients|warning_delete_client'),
        title: this.$t('common|warning')
      })
      if (!res) return
      let payload
      if (this.actualTab.isMyTab) {
        payload = {
          isMyTab: true
        }
      }
      if (this.actualTab.isCompanyTab) {
        payload = {
          isCompanyTab: true,
          companyId: this.actualTab.company._id
        }
      }
      if (this.actualTab.isGroupTab) {
        payload = {
          isGroupTab: true,
          groupId: this.actualTab.group._id
        }
      }
      try {
        await this.deleteClientAction({ _id: client._id, payload })
      } catch (e) {
        console.error(e, 'e')
      }
    }
  }
}
